//订单支付
// mxy
<template>
  <div class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <!-- <div class="pageHead"><page-head title="订单支付" /></div> -->
    <div class="pageHead">
      <span>订单支付</span>
    </div>
    <div class="title">
      <span> 订单编号：</span>
      <span style="color: #004ece"> {{ obj.orderCode }}</span>
    </div>
    <div class="back" @click="back"></div>
    <div class="user">
      <a-row>
        <a-col :span="8">
          <span>商品名称：{{ obj.orderName }}</span>
        </a-col>
        <a-col :span="8">
          <span>商品单价：{{ obj.singlePrice }}元/件</span>
        </a-col>
        <a-col :span="8">
          <span>应付金额：</span>
          <span class="count-price">{{ obj.singlePrice }}元</span>
        </a-col>
      </a-row>
    </div>
    <page-head title="支付方式" />
    <div class="footer">
      <a-spin v-if="!costSucceed" :spinning="loading">
        <div class="payment-method flex-centent">
          <div class="ewm" id="qrcode" ref="qrcode"></div>
        </div>
      </a-spin>
      <div v-if="!costSucceed" class="weixin">
        <img
          class="weapp-pay-icon"
          src="https://api2.eipas.cn/mall-icon/icon-weapp-pay.svg"
        />微信扫码支付
      </div>
      <div v-if="costSucceed" style="font-size: x-large">
        <img src="@/assets/xianshi_chenggong.png" />
        支付成功！
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import * as signalR from '@microsoft/signalr'
import { minheight } from '../components/sameVariable'
import PageHead from '@/components/PageHead'
import QRCode from 'qrcodejs2'
import { getEnvVars } from '@/api/config'

export default defineComponent({
  components: {
    // 'usage-record': UsageRecord,
    'page-head': PageHead,
    // QRCode,
  },
  data () {
    return {
      spinning: false, // 是否加载
      attributeList: [],
      orderInfo: {},
      loading: true,
      costSucceed: false,
      infor: {
        number: 0,
        maxNumber: 1,
        price: 0,
        count: 0,
        typeName: '',
      },
      payUrl: null,
      orderData: {},
      obj: {},
      connection: null, // signalr连接
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.signalRConnection()
    this.getOrderDetails()
  },
  mounted () {
    this.getPayUrl()
    this.costReply()
  },
  // 方法e
  methods: {
    signalRConnection () {
      // const { apiUrl } = getEnvVars()
      // const hubUrl = apiUrl + '/api/chat'
      // const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // // .net core 版本中默认不会自动重连，需手动调用 withAutomaticReconnect
      // const connection = new signalR.HubConnectionBuilder()
      //   .withAutomaticReconnect()
      //   .withUrl(hubUrl)
      //   .build()
      this.connection = this.$store.connection

      // connection
      //   .start()
      //   .then(function () {
      //     // 连接后，先发送当前人信息
      //     const data = { channel: 'PC', userId: userInfo.id }
      //     connection.invoke('UserData', JSON.stringify(data))
      //   })
      //   .catch(err => console.log(err))
    },
    // 返回上一步
    back () {
      this.$router.go(-1)
    },
    getPayUrl () {
      this.orderData = this.$route.query
      const ip = sessionStorage.getItem('ip')
      api
        .get('/api/app/pay/unifiedOrder', {
          params: {
            out_trade_no: this.orderData.orderCode,
            spbill_create_ip: ip,
            trade_type: 2,
          },
        })
        .then(res => {
          const payData = JSON.parse(res.data)
          this.payUrl = payData.xml.code_url['#cdata-section']
          this.qrcode()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    costReply () {
      var _this = this
      // 接到通知信息
      this.connection.on('costSucceed', function (message) {
        const obj = JSON.parse(message)
        _this.costSucceed = true
      })
    },
    getOrderDetails () {
      this.orderData = this.$route.query
      api
        .get('/api/app/sys-ent-order-info/order-info-by-order-code', {
          params: {
            orderCode: this.orderData.orderCode,
          },
        })
        .then(({ data }) => {
          this.obj = data
          console.log(data)
        })
        .catch(err => {
          console.log(err)
          // this.$message.error('数据获取失败' + err)
        })
    },
    // 二维码
    qrcode () {
      this.orderData = this.$route.query
      document.getElementById('qrcode').innerHTML = ''
      const qrcode = new QRCode('qrcode', {
        width: 200, // 设置宽度
        height: 200, // 设置高度
        colorDark: '#000000',
        colorLight: '#ffffff',
        text: this.payUrl,
      })
      qrcode._el.title = '' // 鼠标悬浮不展示title
      this.loading = false
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  background-color: #fff;
  .head {
    padding: 0 40px;
  }

  .back:after {
    content: " ";
    cursor: pointer;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-width: 0 0 2px 2px;
    border-style: solid;
    -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: relative;
    top: -40px;
    left: 15px;
  }
  .title {
    position: absolute;
    left: 960px;
    margin-top: 22px;
  }
  .item {
    margin: 20px 10px 10px;
    padding: 10px;

    .name {
      margin: 0;
      margin-bottom: 10px;
    }

    .price {
      // color: #ffb026;
      font-weight: bold;
    }
    .priceColor {
      color: #ffb026;
      font-weight: bold;
    }
    .subtitle {
      margin: 10px 0 0;
      margin-top: 10px;
      margin-bottom: 0;
      font-weight: normal;
      font-size: 12px;
      color: #a3a3a3;
    }
    .divider {
      height: 1px;
      background-color: #e0e0e0;
    }
  }
  .item_selected {
    border: 1px solid #ffb026;
  }
  .user {
    padding: 20px 40px;
    .count-price {
      font-size: 16px;
      color: red;
      font-weight: 700;
    }
  }
  .footer {
    margin: 20px auto;
  }
  .payment-method {
    width: 240px;
    height: 240px;
    opacity: 1;
    background: #ffffff;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    align-items: center;

    .ewm {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 216px;
      height: 216px;
      border: 2px solid #fdfdfd;
      background-color: #fdfdfd;
    }
  }
  .weixin {
    margin: 10px 66px;
    .weapp-pay-icon {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }
  }
}
.pageHead {
  width: 100%;
  height: 60px;
  font-size: 18px;
  line-height: 60px;
  padding: 0 40px;
  border-bottom: 1px solid #f5f5f5;
}
</style>
